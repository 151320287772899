.page--2022,
.page--2022-ynjlywt0ahjvdwdoldlwmjlk,
.page--2021, 
.page--2021-926db3938d8009965eac19464409466315,
.page--2020,
.page--2019 {
	background-color: $bt-dk-blue;
}

.archive {
	&__hero {
		padding: 4rem 2rem;
		min-height: 60vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: center center;
		text-align: center;

		h1 {
			margin: 0 0 2rem;
			max-width: 800px;

			img {
				width: 100%;
			}
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		gap: 1em;
	}

	&__blog {
		display: flex;
		align-items: center;
		height: 2.5em;
		margin-top: 6rem;
		padding: 0.5em 2em 0.5em 0.5em;
		font-size: 12px;
		color: $bt-white;
		background-color: $bt-dk-blue;
		border-radius: 4em;
		white-space: nowrap;

		&, * {
			transition-property: color background-color;
			transition-duration: 80ms;
		}

		& > span {
			height: 20px;
			padding: 0 0.85em;
			margin-right: 1.5em;
			background-color: $bt-white;
			color: $bt-dk-blue;
			text-transform: uppercase;
			font-size: 0.75em;
			font-weight: 700;
			border-radius: 2em;
			line-height: 20px;
			vertical-align: middle;

			&:after {
				height: 1.5em;
			}
		}

		span > span {
			display: none;
		}

		&:hover {
			background-color: $bt-white;
			color: $bt-dk-blue;			

			span {
				background-color: $bt-dk-blue;
				color: $bt-white;
			}
		}
	}
}

.page--2022 .archive__blog, .page--2022 .button--secondary {
	display: none;
}

.recordings {
	padding: 0;
	margin: 4rem auto;
	max-width: 1200px;

	color: white;

	&__title {
		padding: 0.5em;
		margin: 3em 0;
		font-size: 30px;
		font-weight: 400;
		color: $bt-white;
		line-height: 1;
		text-align: center;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&__grid {
		padding: 0;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 3rem;
		list-style: none;
	}
}

.recording {

	margin-bottom: 2rem;

	&__title {
		margin-top: 0.5em;
		color: $bt-white;
		line-height: 1;
		font-size: 28px;
		font-weight: 400;
	}

	&__tag {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 0.9em;
		padding: 0 0.25em;
		margin-left: 0.25em;
		vertical-align: bottom;
		border-radius: 2px;
		
		background: linear-gradient(280deg, #A900FF 4.65%, #FF5E00 100%);
		
		&__label {
			position: relative;
			top: -1px;
			font-size: 0.5em;
			letter-spacing: 0.15em;
			text-transform: uppercase;
			color: $bt-white;
		}
	}

	&__info {
		padding-left: 1em;
		list-style-type: disc;
		color: rgba(255,255,255,0.75);
		font-size: 14px;
	}

	&__participant {
		margin-bottom: 0;
		font-weight: normal;
	}

	&__note {
		font-style: italic;
	}

}

.video-container {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;

	iframe, video, img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	img {
		cursor: pointer;
	}
}

@media screen and (min-width: $bp-small) {
	.archive__actions {
		flex-direction: row;
	}
}

@media screen and (min-width: 1024px) {

	.recordings__title {
		font-size: 40px;
	}

	.recordings__grid {
		grid-template-columns: 1fr 1fr;
	}
}