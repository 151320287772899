// HEADLINES
h1,h2,h3,h4,h5,h6 {
	color: $bt-dk-blue;
	font-family: $font-display;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 500;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

// BODY
p {
  font-family: $font-body;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: $bt-dk-blue;
  font-weight: 700;
  text-decoration: none;
}