// Agenda Styles

.hide {
	display: none;
}

.sessions {
	padding: 0;
}

.sessions_tz {
	font-size: 14px;
	font-weight: bold;
	color: rgba(0, 38, 62, 0.7);
}

.session {
	display: grid;
	
	border-top: 2px solid rgb(230, 233, 236, 1);
	padding: 1em 1em 0;
	
	&:not(.session--break) {
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 38, 62, 0.03);
		}
	}

	&__time {
		position: relative;
		line-height: 1.3;
		// height: 32px;

		&__start, &__end {
			display: block;
		}

		&__start {
			font-size: 14px;
			font-weight: bold;
		}

		&__end {
			font-size: 13px;
			color: rgb(122, 138, 153, 0.8);
		}

		&:after {
			content: " ";
			position: absolute;
			width: 4px;
			height: 31px;
			top: 0;
			right: 0;
			background: rgba(122, 138, 153, 0.6);
		}

	}

	&--panel &__time:after {
		background-color: rgba(169, 0, 255, 1);
	}
	&--interview &__time:after {
		background-color: rgba(255, 94, 0, 1);
	}
	&--breakout-session &__time:after {
		background-color: rgba(221, 31, 38, 1);
	}
	&--award &__time:after {
		background-color: rgba(255, 153, 0, 1);
	}

	&__duration {
		padding: 0.25em;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		color: rgb(0, 38, 62, 0.7);
		background-color: rgb(122, 138, 153, 0.1);
		border-radius: 4px;
	}

	&__title {
		margin: 0.45em 0 0;
		font-family: "Helvetica Neue", sans-serif;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.3;
		color: rgba(0, 38, 62, 1);
	}

	&__type {
		white-space: nowrap;		

		& > span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 24px;
			padding: 0 18px;
			font-size: 10px;
			font-weight: bold;
			background-color: #EEE;
			border-radius: 12px;
		}

		&--panel > span {
			color: rgba(169, 0, 255, 1);
			background-color: rgba(169, 0, 255, 0.1);
		}
		&--interview > span {
			color: rgba(255, 94, 0, 1);
			background-color: rgba(255, 94, 0, 0.2);
		}
		&--breakout-session > span {
			color: rgba(221, 31, 38, 1);
			background-color: rgba(221, 31, 38, 0.1);
		}
		&--award > span {
			color: rgba(255, 153, 0, 1);
			background-color: rgba(255, 153, 0, 0.2);
		}
	}

	&__speakers {
		list-style: none;
		padding: 0;
		display: flex;

		img {
			display: block;
			position: relative;
			height: 35px;
			width: 35px;
			left: -3px;
			top: -2px
		}

	}

	&__speaker {
		position: relative;
		height: 32px;
		width: 32px;
		border-radius: 100%;
		border: 2px solid white;
		overflow: hidden;
		background-color: $bt-dk-blue;

		& + li {
			margin-left: -8px;
		}

		&--more,
		&--hidden {
			font-size: 12px;
			font-weight: bold;
			text-align: center;
			line-height: 30px;
			color: $bt-white;
		}
	}

	&__description {
		ul, p {
			font-size: 14px;
		}
	}

	&__disclosure {

		svg {
			width: 10px;
		}

		.session--collapsed & > svg {
			transform: rotate(-90deg);
		}

	}

	&__description {
		padding-bottom: 1em;
	}

	&--collapsed &__description {
		display: none;
	}
}

.session {
	grid-template-columns: 3rem 4rem auto 5rem 1rem;
	grid-template-rows: auto auto auto auto;
	grid-column-gap: 1em;
	grid-row-gap: 1em;

	&__time {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	&__duration {
		grid-column: 2/3;
		grid-row: 1/2;
		align-self: start;
	}

	&__title {
		grid-column: 1/5;
		grid-row: 2/3;
		align-self: start;
	}

	&__type {
		grid-column: 4/5;
		grid-row: 3/4;
		justify-self: end;
	}

	&__speakers {
		grid-column: 1/3;
		grid-row: 3/4;
		justify-self: start;
	}

	&__disclosure {
		grid-column: 5/6;
		grid-row: 1/2;
		justify-self: end;
	}

	&__description {
		grid-column: 1/5;
		grid-row: 4/5;
	}
}

@media screen and (max-width: $bp-medium) {
	.session {
		&--break {
			grid-template-columns: 100%;
			grid-template-rows: auto;
			padding-bottom: 1.5em;

			.session__title {
				grid-column: 1/2;
				grid-row: 1/2;
			}

			.session__time,
			.session__duration,
			.session__type,
			.session__speakers
			{
				display: none;
			}
		}
	}
}

@media screen and (min-width: $bp-medium) {

	.session {
		
		grid-template-columns: 4rem 4rem auto 7rem 104px 2rem;
		grid-template-rows: auto auto;
		grid-column-gap: 1.5em;
		grid-row-gap: 1em;

		&__time {

		}

		&__duration {

		}

		&__title {
			grid-column: 3/4;
			grid-row: 1/2;
		}

		&__type {
			grid-column: 4/5;
			grid-row: 1/2;
			align-self: start;
			justify-self: center;
		}

		&__speakers {
			grid-column: 5/6;
			grid-row: 1/2;
			justify-self: end;
			margin-bottom: 0;
		}

		&__disclosure {
			grid-column: 6/7;
			grid-row: 1/2;
			
		}

		&__description {
			grid-column: 3/6;
			grid-row: 2/3;
		}
	}

}

@media screen and (min-width: $bp-large) {

	.session {

		&__description {
			grid-column: 3/4;
		}

	}
}