$nav-mobile-breakpoint: 1100px;

.header {
	background: $bt-dk-blue;
	color: white;
	padding: 0 1.5em;

	display: flex;
	justify-content: space-between;

	position: sticky;
	top: 0;
	z-index: 9999;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__sitename {
		display: flex;
    align-items: center;

		img {
			max-height: 30px;
			margin: 10px 0;
		}
	}

	&__nav {
		//margin-left: auto;

		ul {

			&.top-level {
				display: flex;
				align-items: center;
				height: 100%;
			}
	
			> li {
				position: relative;
				margin: 0;
				transition: all .3s ease;
				padding: 0 1em;
	
				> a {
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 3px;
					font-family: $font-display;
					color: $bt-white;
					cursor: pointer;
					display: flex;
    			align-items: center;
					font-weight: 400;
					transition: opacity 350ms ease;
					-webkit-transition: opacity 350ms ease;
				}

				.nav--parent__disclosure {
					color: $bt-white;
					float: right;
					height: 14px;
					position: relative;
					transform: rotate(180deg);
					width: 1em;
					margin-left: 0.25em;
				}

				&:hover {
					> a {
						opacity: 0.5;
					}
				}
			}
		}

		[hidden] {
			display: none;
		}

		.dropdown { /* ul */
			background: $bt-dk-blue;
			min-width: 100%;
			position: absolute;
			padding-top: 1em;
			left: 0;

			>li {
				padding-bottom: 0.5em;
				
				>a {
					display: block;
					white-space: nowrap;
					font-size: 14px;
				}
			}

			&:not([hidden="true"]) {
				animation-name: mainnavSlideDown;
				animation-duration: 125ms;
				animation-timing-function: ease-out;
			}
		}
	}

	&__social,
	.header__social--mobile {
		display: flex;

		ul {
			display: flex;
			align-items: center;
			height: 100%;

			li {
				align-items: center;
				display: flex;
				justify-content: center;
				padding: 0 5px;
			}
		}

		a > svg {
			height: 20px;
			width: 20px;
			display: block;
			color: $bt-white;
			fill: $bt-white;
			transition: opacity 350ms ease;
			-webkit-transition: opacity 350ms ease;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	&__button {
		margin-right: 20px;
	}

	// DESKTOP NAV
	@media screen and (min-width: $nav-mobile-breakpoint) {
		.nav__toggle,
		.header__social--mobile {
			display: none;
		}
	}
}

.nav__toggle {
	background: none;
	border: none;
	position: relative;
	justify-self: center;
  align-self: center;
	margin-left: calc(var(--nav-child-padding-x) - 8px);
	height: 38px;
	width: 38px;
	padding: 8px;
	padding-left: 0;
	color: $bt-white;
	order: -1;

	svg {
		height: 22px;
		width: 22px;
	}

	&__open {
		display: none;
	}
	&__close {
		display: block;
	}
}

.header__nav--hide-mobile {
	.nav__toggle__open {
		display: block;
	}
	.nav__toggle__close {
		display: none;
	}
}

// MOBILE NAV
@media screen and (max-width: $nav-mobile-breakpoint - 1) {
	.header__nav--hide-mobile .header__nav {
			display: none;
	}
	.header__social { display: none; }

	.header__nav {
		position: absolute;
    top: 50px;
    background: $bt-dk-blue;
    left: 0;
    width: 100%;

		animation-name: mainnavSlideDown;
			animation-duration: 125ms;
			animation-timing-function: ease-out;

		ul {
			flex-direction: column;

			> li {
				width: 100%;
				padding: 1em 1.5em;
			}
		}

		.dropdown.dropdown { 
			position: relative;
			animation: none;
		}

		.header__social--mobile {
			align-items: center;
			width: 100%;
			justify-content: flex-start;

			ul { 
				flex-direction: row;
				margin-right: 1.5rem;
			}

			.button { flex: 2; }
		}
	}
}



// Fast animation for when dropdowns are revealed
@keyframes mainnavSlideDown {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}