// Speaker Bio Page Specific Styles
.page--speaker-page {

	.bio-container .inner-container {
		max-width: 100%;
		padding-top: 2rem;
		padding-bottom: 4rem;
		background-image: url("/images/2022/speaker-page-bg.jpg");
		background-size: cover;
		font-size: 16px;
		height: 100%;
		min-height: 950px;
		display: block;
	}

	.speaker-bio__image {
		max-width: 70%;
		margin-bottom: 2rem;
		min-height: 48%;
	}

	@media screen and (min-width: $bp-small) {
		.speaker-bio__image {
			max-width: 80%;
		}
		.bio-container .inner-container {
			display: flex;
		}

	}

	@media screen and (min-width: $bp-large) {
		.flex-container {
			flex-wrap: nowrap;

			> * { flex-basis: 50%; }
		}

		.speaker-bio__image,
		.speaker-bio__copy {
			max-width: 38%;
			margin-right: 2%;
			margin-bottom: 0;
		}
	}

	.speaker-bio__name {
		max-width: 300px;
		height: auto;
		margin-bottom: 3rem;
	}

	.breadcrumbs {
		display: flex;
		justify-content: flex-start;
		list-style: none;
		padding: 0; 
		margin: 0 0 2rem;
	}
}