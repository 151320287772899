/** COLORS **/
$bt-dk-blue: rgb(0, 27, 44); // #001B2C
$bt-white: rgb(255, 255, 255); // #FFF
$bt-orange: rgba(255, 94, 0, 1); // #FF5E00
$bt-red: rgba(221, 31, 38, 1); // #DD1F26
$bt-violet: rgba(169, 0, 255, 1); // #A900FF
$bt-purple: rgba(92, 6, 140, 1); // #5C068C
$bt-gradient: linear-gradient(180deg, $bt-orange 0%, $bt-red 48.44%, $bt-violet 100%);
$bt-grey-blue: rgba(25, 50, 65, 1); // video placeholder background


/** FONTS **/
$font-body: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-display: 'Jockey One', sans-serif;

/** BREAKPOINTS **/
$bp-xsmall: 460px;
$bp-small: 600px;
$bp-medium: 767px;
$bp-large: 979px;
$bp-xlarge: 1100px;