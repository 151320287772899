.page--thankyou {

	background-color: $bt-dk-blue;
	padding-bottom: 6rem;

	a {
		color: #009CE3;
	}
	section {
		padding: 20px 30px;
		max-width: 1170px;
		margin-left: auto;
		margin-right: auto;
	}

	section+section {
		margin-top: 3rem;
	}

	main, h1, h2 {
		color: $bt-white;
	}

	h1, h2 {
		font-weight: 400;
		line-height: 1;
	}

	h2 {
		font-size: 32px;
	}

	a.button {
		display: inline-block;
		background-color: $bt-dk-blue;
		color: $bt-white;
		padding: 1em 3em;
		text-align: center;

		&--subtle {
			background-color: rgb(121, 148, 164);
			color: $bt-white;
		}
	}

	.thankyou-thankyou {
		text-align: left;
		margin: 0rem;
		width: 100%;

		h1 {
			font-size: 2rem;
		}

		p {
			font-size: 20px;
		}
	}

	.thankyou-becomeamember {
		margin-top: 2rem;
		background-image: url("/images/2022/thankyou-becomeamember.png");
		background-position: right center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 4rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2rem;
	}

	.thankyou-donate {
		display: grid;
		grid-template-columns: 1fr;

		background-image: url("/images/2022/thankyou-donate-background.png");
		background-position: right center;
		background-repeat: no-repeat;
		background-size: cover;

		div {
			padding: 2rem 4rem;
		}
	}

	.membership-benefits {
		list-style: none;
		padding: 0;
		margin: 2rem 0;

		li {
			padding: 1rem;
			display: flex; 
			justify-content: start;
			align-items: center;

			font-family: 'Jockey One', sans-serif;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			letter-spacing: 4px;
			line-height: 1.15;
		}

		svg {
			width: 40px;
			margin-right: 1em;
			color: rgb(121, 148, 164);
		}
	}

	@media screen and (min-width: $bp-medium) {
		.thankyou-thankyou {
			margin: 6rem 19rem 0rem;
			width: 50%;

			h1 {
				font-size: 3rem;
			}
		}
		.thankyou-donate {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 2rem;

			a {
				justify-self: center;
				align-self: center;
			}
		}
	}

	@media screen and (min-width: $bp-xlarge) {

		.page--thankyou section {
			padding: 40px 60px;
		}

		.membership-benefits {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

}