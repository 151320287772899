// Homepage Specific Styles
.page--home {
	background-color: $bt-dk-blue;

	.event-banner {
		p {
			font-size: 14px;
			text-align: center;
			font-weight: 600;
			line-height: 12px;
			color: white;
			background: linear-gradient(271deg, #8003C2, #DD1F26, #ff6300);
			background-size: 600% 600%;
			-webkit-animation: BTGradientAnimation 11s ease infinite;
			-moz-animation: BTGradientAnimation 11s ease infinite;
			animation: BTGradientAnimation 11s ease infinite;
			margin: 0px;
			height: 50px;
			justify-content: center;
			align-items: center;
			display: flex;
		}
		
		@-webkit-keyframes BTGradientAnimation {
			0%{background-position:0% 51%}
			50%{background-position:100% 50%}
			100%{background-position:0% 51%}
		}
		@-moz-keyframes BTGradientAnimation {
			0%{background-position:0% 51%}
			50%{background-position:100% 50%}
			100%{background-position:0% 51%}
		}
		@keyframes BTGradientAnimation {
			0%{background-position:0% 51%}
			50%{background-position:100% 50%}
			100%{background-position:0% 51%}
		}

		.live-status {
			background: #fff;
			border-radius: 3px;
			color: #DD1F26;
			display: inline-flex;
			font-size: 12px;
			margin-right: 10px;
			text-transform: uppercase;
			vertical-align: middle;
			min-height: 28px;
			display: inline-flex;
			align-items: center;
			padding: 0 10px;
		}
		.live-dot {
			height: 6px;
			width: 6px;
			background-color: #DD1F26;
			border-radius: 50%;
			margin-right: 4px;
		}
		.mobile-hide {
			display: none;
			@media screen and (min-width: $bp-large) {
				display: inline-flex;
			}
		}
	}

	.homepage-hero {
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: linear-gradient(to bottom, #001b2c00, #001b2c00, #001b2c00, #071b2c),
    url('/images/2022/BreakThrough-22-Hero-Background.png');
		background-color: $bt-gradient;
		background-position: 50% 30%;
		background-repeat: no-repeat;
		background-size: cover;
		
		/**@media screen and (min-width: $bp-small) {
			height: 100vw;
			background-image: url('/images/breakthrough-2021-savethedate-hero-sm.jpg');
		}

		@media screen and (min-width: $bp-large) {
			height: 50vw;
			background-image: url('/images/breakthrough-2021-savethedate-hero.jpg');
		}**/

		.inner-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 100px;
			padding-bottom: 75px; 

			@media screen and (min-width: $bp-medium) {
				padding-top: 200px;
			}

		}

		img {
			max-width: 85%;
			height: auto;

			@media screen and (min-width: $bp-large) {
				width: 45vw;
				max-width: 750px;
			}

		}

		.created-by-logos {
			height: auto;
			width: auto;
			padding: 0px 12px;

			@media screen and (min-width: $bp-large) {
				height: 20px;
				width: auto;
			}
		}	

		.created-by {
			color: $bt-white;
			padding: 40px 0px;
			span {
				display: block;
				text-align: center;
				font-size: 16px;
				line-height: 16px;
				letter-spacing: 3px;
				font-family: $font-display;
				text-transform: uppercase;
				margin-bottom: 15px;

				@media screen and (max-width: $bp-large) {
					font-size: 12px;
				}
			}
		}

		.save-the-date {
			margin-top: 2rem;
			display: block;

			.register-text {
				width: 100%;

				@media screen and (min-width: $bp-medium) {
					width: 100%;
					max-width: 85ch;
					padding-right: 20px;

					h2, p {
						text-align: left;
					}
				}
			}

			h2 {
				max-width: 600px;
				margin-bottom: 0px;
				font-family: $font-display;
				color: $bt-white;
				font-size: 16px;
				font-weight: 400;
				letter-spacing: 4px;
				text-transform: uppercase;
				text-align: center;

				@media screen and (min-width: $bp-xlarge) {
					display: block;
					font-size: 20px;

				}
			}

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 1.5px;
				color: #fff;
				text-align: center;
			}

			.button--gradient {
				border-radius: 2px;
    			padding: 1rem 2.5rem;
				border-radius: 2px;
				font-size: 14px;
			}

			@media screen and (min-width: $bp-medium) {
				display: flex;
				align-items: center; 
				justify-content: center;
			}
		}

		.scroll-down img {
			width: 70%;
			padding-top: 100px;
			padding-left: 2rem;
		}

		.watch-the-recording {
			margin-top: 2rem;

			p {
				color: #fff;
				font-weight: 600;
				padding: 20px 20px 20px 0;
			}
		}
	}


	.homepage-intro, .homepage-committed {

		h2, li {
			color: $bt-white;
		}

		p {
			color: #B8C7D5;
			font-size: 20px; 
			line-height: 28px;
		}

		a {
			color: #009CE3;
		}
	}

	.homepage-intro,
	.homepage-connect,
	.homepage-committed {

		background-color: $bt-dk-blue;

		img {
			max-width: 100%;
			margin-bottom: 1.5rem;
			min-height: 35vh;
		}

		.icon-list {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			list-style: none;
			padding: 0;
			margin: 2rem 0 4rem;
			width: 100%;
			flex-basis: 100%;
	
			li {
				display: flex;
				justify-content: flex-start;
				align-items: center;
	
				font-family: 'Jockey One', sans-serif;
				font-size: 18px;
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 4px;
				line-height: 1.15;
				padding: 0 0 1em;
			}
	
			svg {
				width: 20px;
				margin-right: 1em;
				color: #B8C7D5;
			}
		}

		@media screen and (min-width: $bp-large) {
			img { 
				max-width: 50%;
				margin-bottom: 0;
				min-height: 50%;
			}

			.icon-list {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				li {
					padding: 0 1.5rem;
				}

				svg {
					width: 30px;
				}
			}
		}
	}

	.homepage-speakers {
		background-color: $bt-white;
		padding-bottom: 6rem;
		padding-top: 6rem;

		h2 {
			color: $bt-dk-blue;
			text-align: center;
			padding-bottom: 50px;
			letter-spacing: 3px;

			@media screen and (min-width: $bp-large) {
				background: url('/images/2022/speakers-stroke-background.png') no-repeat center center;
				background-size: 613px;
				padding-top: 50px;
			}
		}

		h3 {
			color: $bt-dk-blue;
			text-align: center;
			margin-top: 4rem;
			margin-bottom: 0;
		}

		// Speaker List styles in _speakers.scss
	}


	.homepage-sessions {
		background-color: $bt-white;
		padding-bottom: 9rem;
		padding-top: 6rem;
		margin-top: 0rem; 
	}


	.homepage-committed {
		background-color: $bt-dk-blue;
		background-position: 99% 73%;
    	background-size: 10%;
    	background-repeat: no-repeat;
    	background-attachment: fixed;
		color: $bt-white;
		padding-bottom: 3rem;
		padding-top: 4rem;

		h2 {
			color: $bt-white;
			text-align: center;
		}

		.flex-container {
			margin-top: 5rem;
		}

		.register-link {
			color: $bt-white;
		}

		.icon.arrow {
			transition: transform 200ms ease-in-out;
			color: #5d078c;
			height: 12px;
			width: 12px;
			margin-top: -10px;
		}

		.button--gradient {
			border-radius: 2px;
			font-size: 14px;
			padding: 1rem 2.5rem;
		}

		.grid {
			display: grid;
			grid-template-columns: 200px 200px;
			grid-row: auto auto;
			grid-column-gap: 20px;
			grid-row-gap: 20px;

			@media screen and (max-width: $bp-small) {
				grid-template-columns: 1fr 1fr;
				width: 100%;
			}

			&-item {
				background-image: linear-gradient(49deg, #7005aa 31%, #c8137d 47%, #c0262d 66%, #ff5e00);
				padding: 4px;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 30px;
				padding-bottom: 20px;

				span,
				svg {
					z-index: 2;
					position: relative;
				}

				&:before {
					background-image: linear-gradient(180deg,#001b2c,#001b2c);
					content: "";
					height: calc(100% - 10px);
					position: absolute;
					width: calc(100% - 10px);
					top: 5px;
					left: 5px;
				}

				svg {
					max-width: 60%;
    			margin-bottom: 10px;
				}

				span {
					font-size: 16px;
					line-height: 22px;
					font-weight: 700;
					text-align: center;
				}
			}
		}
	}

	.homepage-sessions {
		h2 {
			color: $bt-dk-blue;
			margin-bottom: 1em;
			padding: 0.7em 0;
			text-align: center;

			@media screen and (min-width: $bp-large) {
				background: url('/images/2022/agenda-stroke-background.png') no-repeat center center;
				background-size: contain;
			}
		}
	}

	.homepage-register {
		background: url('/images/what-arrows.svg');
		background-color: $bt-white;
    	background-position: 50% 110%;
    	background-repeat: no-repeat;
    	background-size: 11%;
    	padding-bottom: 6rem;
		overflow-x: scroll;
		padding-top: 8rem;

		h2 { text-align: center; }

		.mktoForm {
			margin: 6rem auto;
		}

		.interviews {
			background: #edf1f3;
			max-width: 800px;
			margin: 0 auto 6rem;
			padding: 20px;
			font-size: 14px;
		}
	}
}