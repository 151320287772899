// Speaker List Styles
.speaker-list {
	width: 100%;
	display: grid;
	align-content: space-between;
	align-items: stretch;
	justify-content: center;
	justify-items: center;
	grid-column-gap: 13px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-auto-columns: 1fr;
	padding-left: 0;

	grid-template-columns: 2.25fr 2.25fr;

	> li {
		display: block;
		padding-right: 20px;
    	padding-left: 20px;
		position: relative;

		grid-auto-columns: 1fr;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
		min-height: 270px;
	}

	.headshot-container {
		height: 0;
    	padding-top: calc(1000 / 1072 * 100%);
    	position: relative;
	}

	&__headshot {
		position: absolute;
		top: 0;
		left: -40px;
    max-width: 120%;
		height: auto; 
	}

	&__name {
		// left: 20px;
    	max-width: 75%;
		min-height: 35px;
		display: block;
		margin-top: 70px;
		position: relative;
	}

	&__title {
		color: #80939F;
    	max-width: 80%;
		margin-bottom: 0px;
		margin-top: 25px;
    	font-weight: 700;
    	font-size: 14px;
		line-height: 18px;
		position: relative;
	}

	&__link {
		color: $bt-dk-blue;
    	font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		
		svg {
			color: $bt-dk-blue;
			height: 12px;
			transition: transform .2s ease-in-out;
			width: 12px;
			top: 1px;
			position: relative;
		}
	}

	@media screen and (min-width: $bp-large) {
		grid-template-columns: 1fr 1fr 1fr;

		> li {
			padding-right: 40px;
    		padding-left: 40px;
			min-height: 430px;
		}
	}
}