@import "includes/normalize";
@import "includes/variables";
@import "includes/typography";
@import "components/siteheader";
@import "components/sitefooter";
@import "components/speakers";
@import "components/agenda";

@import "pages/homepage";
@import "pages/speaker-page";
@import "pages/archive";
@import "pages/thankyou";
@import "pages/recordings";


// GENERAL STYLES
html {
  font-size: 18px;
	line-height: 1.4rem;
	color: $bt-dk-blue;
}

body {
	font-family: $font-body;
	position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.container {
	max-width: 100vw;
	width: 100%;
  	margin: 0 auto;
}

.inner-container {
	max-width: 1170px;
	width: 100%;
	margin-left: auto;
  	margin-right: auto;

	@media screen and (max-width: 1170px) {
		padding-right: 1.5rem;
  		padding-left: 1.5rem;
	}
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
	flex-wrap: wrap;

	@media screen and (min-width: $bp-large) {
		flex-direction: row;

		> * { 
			flex: 1;
			padding: 0 1.5rem;

			&:first-child { padding-left: 0; }
			&:last-child { padding-right: 0; }
		}

	}
}

.flex {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

section {
	scroll-margin-top: 7rem;
}

section+section {
	margin-top: 5rem;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

// BUTTONS
.button {
	display: block;
  	padding: .75rem;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	transition: 0.2s all ease-out;
	white-space: nowrap;
	font-size: 14px;

	background: $bt-white;
	color: $bt-dk-blue;
	border: 3px solid $bt-white;


	&--gradient {
		background: linear-gradient(45deg, $bt-violet 5%, #c9137a 35%, $bt-red 65%, $bt-orange 95%, $bt-orange);
		color: $bt-white;
		border: none;
	}

	&--secondary {
		border: 2px solid $bt-white;
		background: transparent;
		color: $bt-white;
	}
}

/* Hiding the secondary button and blog recap button until we have that content ready. */
.page--2021-926db3938d8009965eac19464409466315 .button--secondary, .page--2021-926db3938d8009965eac19464409466315 .archive__blog {
	display: none; 
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
	margin: 0;
}